import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { from, Observable, Subject, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";
import { NotificationDialogService } from "../services/notification-dialog.service";
import { PublicConfigService } from "../services/public.config.service";
import { SsoAuthServiceNew } from "../services/sso-auth-new.service";

@Injectable({
  providedIn: "root",
})
export class LoaderStateInterceptor implements HttpInterceptor {
  requestCount = 0;

  noLoaderRoutes = [
    "loader=false",
    "api/dataset/dropdown", //location control
    "formsubmission",
    "selfregister",
    "upsert-judgement-step",
  ];

  token = '';
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private notificationDialogService: NotificationDialogService,
    private publicConfigService: PublicConfigService,
    private ssoAuthService: SsoAuthServiceNew
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCount++;
    if (this.noLoaderRoutes.filter(url => req.url.includes(url)).length === 0) {
      this.loaderService.showLoader();
    } else if (req.url.includes('formsubmission/submit')) {
      // show loading state on form submission specifically
      this.loaderService.showLoader();
    }
    return from(this.ssoAuthService.getJwt()).pipe(
      tap(token => this.token = token),
      switchMap(() => {
        if (this.token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${this.token}`,
            },
          });
        }
        return next.handle(req);
      }),
      catchError((error) => this.handleResponseError(error, req, next)),
      tap((res) => this.handleBodyError(res)),
      finalize(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.loaderService.hideLoader();
        }
      })
    );
  }

  /**
   * Handle response errors
   * @param error 
   * @param req 
   * @param next 
   * @returns error
   */
  handleResponseError(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {

    // handle SSO errors
    if (error.url.startsWith(this.publicConfigService.getCarrotSSOConfig().ssoBaseUrl)) {
      this.handleSSOError(error);
      return throwError(() => error);
    }

    // if BE returns token outdated response we try to refresh it with SSO and retry the request with the new token
    if(error.status === 400 && error.error?.errors?.find((e: any) => ["BE_API_JwtOutdated"].includes(e.rpErrorCode))) {
      const oldJWT = req.headers.get("Authorization").split(" ")[1];
      return this.refreshToken(oldJWT).pipe(
        switchMap(() => {
          if (this.token) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.token}`,
              },
            });
          }
          return next.handle(req);
        }),
        catchError(e => {
          console.error('Could not refresh token...', e);
          return throwError(() => e);
        })
      )
    }

    // skip admin review specific error so we can handle it on component level (on overtake action)
    if(error.status === 403 && error.error?.errors?.find((e: any) => ["BE_API_AR_ActionNotAllowed_NotAssignedToCurrentUser"].includes(e.rpErrorCode))) {
      return throwError(() => error);
    }

    // redirect to 403 on BE_API_S_AccessToStageDenied, BE_API_J_AccessDenied codes
    if (error.status === 403 && error.error?.errors?.find((e: any) => ["BE_API_S_AccessToStageDenied", "BE_API_J_AccessDenied"].includes(e.rpErrorCode))) {
      this.router.navigate(["/403"]);
      return throwError(() => error);
    }

    // handle all other cases
    let APIErrorStatus = `${error.status}`;
    let APIErrorMessage = error.error?.errors?.map((e: any) => e.clientMessage).join(" ");

    if (error.status === 500) {
      APIErrorStatus = "";
      APIErrorMessage = `An unknown error occurred. Please check your internet connection and try again. If this doesn't help, contact {supportEmailAddress}.`;
    }

    // handle case where errors are empty but we have traceId
    if (!error.error?.errors && error.error?.traceId) {
      APIErrorMessage = `${error.error.title ? `${error.error.title}. `: ""}Trace ID: ${error.error.traceId}`;
    }

    // show error dialog
    this.notificationDialogService.confirm("An error occurred", APIErrorStatus, APIErrorMessage, "Ok", "600px")
    
    return throwError(() => error);
  }

  /**
   * Handle body errors from the API
   * @param res 
   */
  handleBodyError(res: HttpEvent<any>) {
    if (res instanceof HttpResponse === false || res.body.success || res.body.success === undefined) {
      return;
    }
    const errors = res.body.errors[0];
    const errorClientMessage = errors.clientMesssage;
    const errorCode = errors.rpErrorCode;
    const responseURL = res.url;

    this.notificationDialogService.confirm(
      `An error occurred on ${responseURL}`,
      errorCode,
      errorClientMessage,
      "Ok",
      "600px"
    );
  }

   /**
   * Handle SSO API errors
   * @param error 
   */
  handleSSOError(error: any) {
    const ssoNoErrorCodes = [400100, 400130, 400170, 404101, 404102];
    const responseErrorCodes = error.error?.errors?.map((e: any) => e.errorCode) as number[];
    const responseErrorMessages = error.error?.errors?.map((e: any) => e.message).join(" ");

    // show error dialog if SSO error code is not in the noErrorCodes list
    if (responseErrorCodes.filter(e => !ssoNoErrorCodes.includes(e)).length > 0) {
      this.notificationDialogService.confirm("An error occurred", `${responseErrorCodes[0]}`, responseErrorMessages, "Ok", "600px");
    }
  }

  /**
   * Try to refresh user token on expire and block other API calls until refresh is finished
   * @param oldJWT 
   * @returns Observable
   */
  refreshToken(oldJWT: string): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return from(this.ssoAuthService.hardRefreshJWT(oldJWT)).pipe(
        tap((token) => {
          this.token = token;
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next({});
        }),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          return throwError(() => error);
        })
      );
    }
  }
}
