import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormSubmissionService } from '../services/form-submission.service';
import { RpUser } from 'src/app/shared/model/user.model';
import { FormSubmission, FormSubmissionPOC, FormSubmissionPayload } from '../model/form-submission';
import { FormioSubmissionChanges } from '../model/formio-submission';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormDetails, FormStageTypes } from '../model/form-details';
import { FormHeaderDataService } from '../services/form-header-data.service';
import { DateTime } from 'luxon';
import { utcToCompTimePipe } from 'src/app/shared/pipes/utc-to-comp-time.pipe';
import { NotificationDialogService } from 'src/app/shared/services/notification-dialog.service';
import { ConfirmationDialogService } from 'src/app/shared/services/confirmation-dialog.service';
import { Subject, Subscription, forkJoin, timer } from 'rxjs';
import { concatMap, debounce } from 'rxjs/operators';
import { FormioComponent } from '@formio/angular';
import { replaceFebaseurl } from 'src/app/shared/utils/general.utils';
import { PublicConfigService } from 'src/app/shared/services/public.config.service';
import { IUnsavedFormData } from 'src/app/shared/guards/unsaved-form-data.guard';
import { StickyHeaderService } from 'src/app/shared/services/sticky-header.service';
import { sanitizeConfigOptions } from '../model/form-sanitize-config';
import { CheckboxDialogService } from 'src/app/shared/services/checkbox-dialog.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SsoAuthServiceNew } from 'src/app/shared/services/sso-auth-new.service';

@Component({
  selector: 'app-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent implements OnInit, IUnsavedFormData, OnDestroy {
  @ViewChild("componentHeader") componentHeader: ElementRef;
  @ViewChild(FormioComponent, { static: false }) formio: FormioComponent;

  public formDetails: FormDetails;

  public showDeadLine: boolean = false;
  public enableEdit: boolean = false;
  public formHeaderData: any;
  public deadlineISO: string;
  public toggleSaveIcon: boolean = true;

  public windowWidth: number;
  private nowISO: string = DateTime.fromJSDate(new Date()).setZone("UTC").toISO();

  public submitted: boolean = false;
  public isReadonly: boolean = false;

  public stageId: string;
  public formDefinition: any;
  private rpUser: RpUser;
  public formData: FormSubmissionPOC;
  
  public loading: boolean = true;

  public formSubmission: FormSubmission;

  private _changedFieldsIds: {} = {};
  private changedFieldsForSaving: any;

  private unsavedChangeDetected: boolean = false;
  private shouldSubmit: boolean = false;

  private $autoSave = new Subject<any>();
  private debounceTimer: number = 1000;

  private fieldsArr: any;
  private scrolledIndex: number = null;
  private scrolledElement: any = null;

  private $currentFormState = new Subject<any>();

  // this is used to calculate panels for which the prev/next buttons will scroll you through
  private scrollablePanels: any[] = [];

  // this is used to trigger dashboard redirect only after sumbitting the form
  private submittedNow: boolean = false;

  // this is used as a failsafe, to pass previous saved value if autosave fails
  private passableState: any;

  // this is used to keep showing errors on inputs after you have clicked submit and have flagged at least one question as invalid
  private keepFieldErrors: boolean = false;

  // TODO - add nullify me method

  // NOTE - if something does not show on the form, make sure to check if it's listed here - it should be if it's using a custom tag, or a custom attribute
  renderOptions = { 
    sanitizeConfig: sanitizeConfigOptions
  };

  private routeSub: Subscription;

    // this is used after form submitting to prevent interactions with the form until the form submit call has passed
    makeInert: boolean = false;

  constructor(
    private formSubmissionService: FormSubmissionService,
    private ssoAuthService: SsoAuthServiceNew,
    private tabTitleService: TabTitleService,
    private route: ActivatedRoute,
    private formHeaderDataService: FormHeaderDataService,
    private utcToCompPipe: utcToCompTimePipe,
    private notificationDialogService: NotificationDialogService,
    private confirmationDialogService: ConfirmationDialogService,
    private checkboxDialogService: CheckboxDialogService,
    private router: Router,
    private publicConfigService: PublicConfigService,
    private stickyHeaderService: StickyHeaderService,
    private commonService: CommonService,
  ) {
  }

  async ngOnInit() {

    setTimeout(() => {
      this.stickyHeaderService.updateComponentHeaderRef(this.componentHeader);
    }, 0);

    // fix for sticky header for participants that haven't submitted reg form yet
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // if we arrive at this point, the user tried to navigate to a different page, but couldn't due to enforced registration
        // update component header ref
        setTimeout(() => {
          this.stickyHeaderService.updateComponentHeaderRef(this.componentHeader);
        }, 0);
      };
    });

    // autosave obs
    this.$autoSave
      .asObservable()
      .pipe(
        debounce(() => (timer(this.debounceTimer))),
        concatMap(submissionData => {
          this.toggleSaveIcon = true;
          return this.saveFormData(submissionData);
        })
      ).subscribe(() => {});
      // empty sub above, just to active the obs
      // we don't need the response from concatMap, because it's processed inside this.saveFormData

    // after autosave obs
    this.$currentFormState
    .asObservable()
    .subscribe(async currentFormState => {
      if (currentFormState) {
        // ^^ means that autosave has passed and we have response
        this.formHeaderData.lastSaved = currentFormState.lastSaved;
        this.formHeaderData.progress = currentFormState.progress;

        // check if form is submitted now
        // if (currentFormState.submit === true && this.submittedNow) {
        //   await this.ssoAuthService.forceRpUserRefresh();
        //   const capitalizedFormTitle = this.formDetails.name.charAt(0).toUpperCase() + this.formDetails.name.slice(1);
        //   this.notificationDialogService.confirm('Success!', '', `Wonderful! Your ${capitalizedFormTitle} has been received and officially recorded. Save a version of the ${capitalizedFormTitle} for your records (you will not receive a confirmation email).`, 'Go to Dashboard', '600px')
        //     .then((confirmed) => {
        //       // if confirmed === true - user confirmed, if false - user clicked cancel button
        //       this.router.navigate(['/']);
        //       if (confirmed) {
        //         // console.log('confirm leave');
        //       } else {
        //         // console.log('do not confirm leave');
        //       };
        //     })
        //     .catch(() => {
        //       // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
        //       this.router.navigate(['/']);
        //     });
        // }

        this.deadlineISO = currentFormState.deadline;
        this.nowISO = DateTime.fromJSDate(new Date()).setZone("UTC").toISO();
        if (this.deadlineISO < this.nowISO) {
          this.formHeaderData.isDeadlinePassed = true;
        } else {
          this.formHeaderData.isDeadlinePassed = false;
        }

        if (this.formHeaderData.lastSaved === null) {
          this.formHeaderData.state = "INITIAL";
        } else {
          if (currentFormState.submit === false) {
            this.formHeaderData.state = "INPROGRESS";
          } else {
            this.formHeaderData.state = "SUBMITTED";
          }
        }

        for (let key in this.changedFieldsForSaving) {
          const savedValue = this.changedFieldsForSaving[key];
          const currentValue = currentFormState.data[key];
          if (currentValue === JSON.stringify(savedValue)) {
            delete this._changedFieldsIds[key];
          }
        }

        // updating only specific properties with the latest info from our API
        // we don't want to update this.formData.data with currentFormState.data,
        // to preserve anything entered between sedning the autosave request and receiving data
        this.formData.readonly = currentFormState.readonly;
        this.formData.submit = currentFormState.submit;
        this.formData.questionPanels = currentFormState.questionPanels;

        // update list of scrollable panels
        this.scrollablePanels = this.calculateScrollablePanels();
        // since the value of the input fields in currentFormState is stringified JSON (it comes from BE)
        // we are passing this.formData because the value of the input fields is JSON.parsed
        this.markQuestionsValid(this.formData);
        this.toggleSaveIcon = false;
        this.unsavedChangeDetected = false;
      } else {
        // if autosave fails
        if (this.submittedNow) {
          // if autosave (submit) fails while you are trying to submit
          this.notificationDialogService.confirm('An error occured', '', `Your form wasn't submitted! <br> Please, try again later!`, 'Ok', '600px')
            .then((confirmed) => {
              // if confirmed === true - user confirmed, if false - user clicked cancel button
              if (confirmed) {
                // console.log('confirm leave');
              } else {
                // console.log('do not confirm leave');
              };
            })
            .catch(() => {
              // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
            });
          this.submittedNow = false;
        }
        this.toggleSaveIcon = false;
      }
      // end after autosave obs
    });

    this.windowWidth = window.innerWidth;

    this.route.data.subscribe(async (response: any) => {
      this.formDetails = response.formDetails;
      this.showDeadLine = this.formDetails.stageType !== FormStageTypes.RegistrationForm;
      this.enableEdit = this.formDetails.stageType === FormStageTypes.RegistrationForm;
      this.stageId = this.formDetails.stageId;

      this.formSubmissionService.init(
        this.formDetails.userId
      );

      // get current rp user to check if the user is Master Admin
      this.rpUser = await this.ssoAuthService.getCurrentRpUser();

      this.tabTitleService.setTabTitle(this.formDetails.name);

      await forkJoin([this.getHeaderData(), this.loadFormDefinition(), this.loadFormData()]).toPromise();
      this.scrollablePanels = this.calculateScrollablePanels();
      this.loading = false;
    });
  }

  async getHeaderData() {
    try {
      const response: any = await this.formHeaderDataService
        .GetHeader(this.formDetails.stageId, this.formDetails.userId)
        .toPromise();
      this.formHeaderData = response;
      this.submitted = this.formHeaderData.isSubmitted;

      if (this.formHeaderData.lastSaved === null) {
        this.formHeaderData.state = "INITIAL";
      } else {
        if (this.submitted === false) {
          this.formHeaderData.state = "INPROGRESS";
        } else {
          this.formHeaderData.state = "SUBMITTED";
        }
      }

      this.toggleSaveIcon = false;

      this.deadlineISO = this.formHeaderData.deadline;
      if (this.deadlineISO < this.nowISO) {
        this.formHeaderData.isDeadlinePassed = true;
      } else {
        this.formHeaderData.isDeadlinePassed = false;
      }
      if (!this.formHeaderData.isDeadlinePassed && !this.submitted) {
        if (this.windowWidth > 1200) {
          this.formHeaderData.toolTipText = "Submission Deadline";
        } else {
          this.formHeaderData.toolTipText =
            "Submission Deadline: " +
            this.utcToCompPipe.transform(this.formHeaderData.deadline, "dateTransform") +
            " " +
            this.utcToCompPipe.transform(this.formHeaderData.deadline, "timeTransform");
        }
      }

      // if the form is submitted and you are not MA, then the form is in readonly mode
      if (this.formHeaderData.isSubmitted && this.rpUser.currentRole.name !== 'Master Admin') {
        this.isReadonly = true;
      }

      // if the form deadline has passed and you are not MA, then the form is in readonly mode
      if (this.formHeaderData.isDeadlinePassed && this.rpUser.currentRole.name !== 'Master Admin') {
        this.isReadonly = true;
      }

    } catch (error) {
      console.log(error);
    }
  }

  async loadFormDefinition() {
    const formDefinitionResponse = await this.formSubmissionService.loadFormDefinition(this.stageId);
    if (formDefinitionResponse) {
      this.formDefinition = JSON.parse(formDefinitionResponse);
    }
  }

  async loadFormData() {
    this.formData = await this.formSubmissionService.loadFormData(this.stageId, this.formDetails.userId);
    if (this.formData.data) {
      for (let [key, value] of Object.entries(this.formData.data)) {
        if (value !== null) {
          this.formData.data[key] = JSON.parse(value);
        }
      };
    }
  }

  formioReady(event) {
    // formio ready event
    replaceFebaseurl(this.publicConfigService.feBaseUrl);
    if (this.formData.data) {
      // if there is no form data on initial load, then questions should not be marked as valid
      this.markQuestionsValid(this.formData);
    };

    // this is used to make links inside content components to open in new tab
    const contentPanelsWithExternalLinks = document.querySelectorAll('.formio-component-content.external-links-inside');
    contentPanelsWithExternalLinks.forEach(panel => {
      panel.querySelectorAll('a').forEach(aTag => {
        aTag.setAttribute('target', '_blank');
      });
    });
  }

  async onFormChange(submissionChanges: FormioSubmissionChanges) {

    if (!submissionChanges.isModified && !submissionChanges.isChanged) return; //preventChanges an initial call to backend

    if (submissionChanges.data) {

      // toggle unsaved changes check
      this.unsavedChangeDetected = true;

      // going through flags.changes in order to pull all fields that are changed with this event
      // this is used so we can properly update all fields that are changed via autofill action
      // if there is no autofill action, but only user input, this updates only the field that the user interacted with
      submissionChanges.flags.changes.forEach(changedField => {
        if ((changedField as any)?.component?.key) {
          const changedFieldId = changedField["component"].key;
          // adding changed fields keys in an array
          this._changedFieldsIds[changedFieldId] = true;
        }
      });
      // get changed fields for saving based on the changedFieldsIds from above
      this.changedFieldsForSaving = await this.getChangedFieldsForSaving(submissionChanges);

      let submitValue: boolean = false;
      if (this.formData.submit && this.rpUser.currentRole.name === 'Master Admin') {
        submitValue = true;
      }

      let submission: FormSubmissionPOC = {
        stageId: this.stageId,
        userId: this.formDetails.userId,
        data: this.changedFieldsForSaving,
        readonly: false,
        submit: submitValue,
      }

      // keep showing errors if you interact with the form after clicking submit and have had invalid questions
      if (this.keepFieldErrors) {
        this.formio.formio.checkValidity(null, true, null, false)
      };

      // this.refreshEmitter.emit({
      //   submission: {
      //     data: submissionChanges.data
      //   }
      // });

      // update the variable for formData.data manually, because formio doesn't update it when changing input values
      // e.g. no two way data binding
      if (this.formData.data) {
        submissionChanges.flags.changes.forEach(changedField => {
          // update all changed fields with the respective values
          this.formData.data[changedField['component'].key] = changedField.value;
        });
      } else {
        // if form data doesn't exists we need to add all of the changes to it
        this.formData.data = structuredClone(submissionChanges.data);
      }
      this.$autoSave.next(submission);
    };
  }

  private async getChangedFieldsForSaving(submissionChanges) {
    let changedFields = {};

    if (!this.formHeaderData.lastSaved) {
      // send all fields to Platform if there is no data in the form (initial save)
      changedFields = submissionChanges.data;

      // check for number fields, because their initial value is null and they are not returned from form changes event
      // check form definition for any components with type number
      // because their initial value is null
      // and add them manually as an empty string
      this.formDefinition.components.forEach(component => {
        component.components.forEach(innerComponent => {
          if (innerComponent.type === 'number') {
            if (!changedFields[innerComponent.key]) {
              changedFields[innerComponent.key] = "";
            };
          };
        });
      });
    } else {
      // add edited fields
      for (let key in this._changedFieldsIds) {
        changedFields[key] = submissionChanges.data[key];
      }

      const newFormIoFields = [];
      for (let formioKey in submissionChanges.data) {
        if (!this.formData.data.hasOwnProperty(formioKey)) {
          //new field in FormIO detected
          newFormIoFields.push(formioKey);
        }
      }

      // add new fields
      for (let key of newFormIoFields) {
        changedFields[key] = submissionChanges.data[key];
      }
    }

    // If there's an empty field and its default Form.io value is undefined it's not being sent to Form.io or BE
    // (maybe it has something to do with undefined not being a valid json value and it's not being serialized)
    Object.keys(changedFields).forEach(key => {
      if (changedFields[key] === undefined) {
        changedFields[key] = "";
      }
    });

    return changedFields;
  }

  async submitForm(triggerNotification = false) {

    try {
      const submitResp = await this.formSubmissionService.submit(this.stageId, this.formDetails.userId, triggerNotification);
      await this.ssoAuthService.reloadPlatformUser();
      const capitalizedFormTitle = this.formDetails.name.charAt(0).toUpperCase() + this.formDetails.name.slice(1);
      this.notificationDialogService.confirm('Submitted!', '', `Your ${capitalizedFormTitle} is complete and has been submitted. Save a copy of the form for your records (click on printer icon at top right of form).`, 'Go to Dashboard', '600px')
        .then((confirmed) => {
          // if confirmed === true - user confirmed, if false - user clicked cancel button
          this.router.navigate(['/']);
          if (confirmed) {
            // console.log('confirm leave');
          } else {
            // console.log('do not confirm leave');
          };
        })
        .catch(() => {
          // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
          this.router.navigate(['/']);
        });
    } catch (error) {
      console.log(error);
      this.makeInert = false;
    }
  }

  async saveFormData(submissionData) {
    let submission: FormSubmissionPayload = {
      stageId: this.stageId,
      userId: this.formDetails.userId,
      data: submissionData.data,
      readonly: submissionData.readonly,
    };
    try {
      const resp = await this.formSubmissionService.saveFormData(submission);
      // cache the response, so we can pass it next time if autosave fails
      this.passableState = resp;
      this.$currentFormState.next(resp);
    } catch (error) {
      console.log(error);
      this.$currentFormState.next(this.passableState);
    }
  }

  // TODO
  async unsubmitForm() {
    try {
      await this.formHeaderDataService.unsubmitRegistrationForm(this.formDetails.userId).toPromise();
      this.isReadonly = false;
      // force form refresh by briefly hiding and then showing the form, while changing the state
      this.loading = true;
      this.formHeaderData.state = "INPROGRESS";
      this.formData.submit = false;
      this.submitted = false;
      setTimeout(async () => {
        await this.ssoAuthService.reloadPlatformUser();
        this.loading = false;
      }, 0);
    } catch (error) {
      console.log(error);
    }
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  getClass(score: number, deadline: boolean, submitted: boolean) {
    if (deadline == true && submitted == false) return "darkgrey";
    if (score == 0) return "grey";
    if (score > 1 && score < 100) return "blue";
    if (score == 100) return "green";
  }

  changeScrollItem(event) {
    const target = event.target;
    const targetType = target.type; // text or textarea
    const targetTagName = target.tagName; // INPUT or TEXTAREA
    let foundItemIndex;
    if (
      (targetTagName === "INPUT" || targetTagName === "TEXTAREA") &&
      (targetType === "text" || targetType === "textarea")
    ) {
      if (target.id.split("-")[1]) {
        const uniqueIdentifier = target.id.split("-")[1];

        // TODO - update this to work with formData.questionPanels instead of formData.data
        // if (!this.fieldsArr && this.formData.data) {
        //   this.fieldsArr = Object.keys(
        //     this.formData.data
        //   ).map((key) => [key, this.formData.data[key]]);
        //   // removes submit statement(?)
        //   this.fieldsArr.pop();
        // }

        // foundItemIndex = this.fieldsArr.findIndex(
        //   (element) => element[0] === uniqueIdentifier
        // );
        // this.scrolledIndex = foundItemIndex;
      }
    }
  }

  calculateScrollablePanels() {
    // temp variable for calculating scrollable panels
    let scrollablePanelsTemp: any[] = [];

    // this.formData.data can be empty object {}, so we need to check for that as well
    if (!this.formData.data || Object.keys(this.formData.data).length === 0) {
      // if there is no form data, the user has not interacted with the form yet, thus we don't have questionPanels to go through
      this.formDefinition.components.forEach(component => {
        // iterate through all components and look inside the inner components to look for editable components (defined with input: true)
        if (component.components) {
          // filter all components in order to find the ones that are editable
          let editableComponentsArray = component.components.filter(innerComponentEditable => {
            if (innerComponentEditable.input) {
              return innerComponentEditable;
            }
          });
          // if there is at least one editable component
          // push the parent component to the scrollable panels array
          if (editableComponentsArray[0]) {
            scrollablePanelsTemp.push(component);
          }
        };
      });
    } else {
      // if there is form data, the user has interacted with the form at least once and we have questionPanels coming from BE
      // we are getting the component panels and their order from the form definition
      // and we are now passing all question panels and inactive ones will be checked later in the scroll function
      scrollablePanelsTemp = this.formDefinition.components.filter(component => {
        return this.formData.questionPanels.some((questionPanel) => {
          return component.key === questionPanel.key;
        });
      });
    };
    return scrollablePanelsTemp;
  }

  nextPrev(direction: string) {
    // current scrolled index at the time of click
    // changed later in the code in certaion conditions
    let index: number;
    
    // TODO - THE INDEX ABOVE is glitching when the scrollable panels are updated - the index remains in it's old value
    // and this means that we can have 2 scrollable panels, but the index can be 6

    if (direction === "next") {
      index = this.scrolledIndex === null ? 0 : this.scrolledIndex + 1
    } else {
      index = this.scrolledIndex === null
        ? this.scrollablePanels.length - 1
        : this.scrolledIndex - 1;
    }

    // n - counter for iterating through all elements of the array
    for(let n = 0; n < this.scrollablePanels.length; n++) {
      // when moving in next direction and we've reached the end of the array based on scrolled index
      // go back to first item and continue the cycle
      if (direction === "next" && index === this.scrollablePanels.length) {
        index = 0;
      }

      // when moving in prev direction and we've reached the start of the array based on scrolled index
      // go to the last item and continue the cycle
      if (direction === "prev" && index === -1) {
        index = this.scrollablePanels.length - 1;
      }

      // assigning index and scrolled element
      this.scrolledElement = this.scrollablePanels[index];
      this.scrolledIndex = index;

      // skipping valid question panels
      // and scroll only to invalid (not completed)
      if (this.formData.questionPanels && this.formData.questionPanels[index].isValid === true) {
        // if element is valid, increase or decrease the index and run the for loop again
        if (direction === "next") {
          if (index === this.scrollablePanels.length) {
            index = 0;
          } else {
            index++;
          }
        }
        if (direction === "prev") {
          if (index === -1) {
            index = this.scrollablePanels.length - 1;
          } else {
            index--;
          }
        }
        // if above conditions are met, we want to cancel this iteration of the loop
        // and continue with the next one until we find an invalid question panel
        continue;
      } else {
        // scroll to element
        this.scrollToPanelbyKey(this.scrolledElement.key);
        // break code to exit the loop and prevent further checks
        break;
      }
    }
  }

  scrollToPanelbyKey(panelKey: string) {
    // get element to which we want to scroll
    const element: any = document.querySelector(".formio-component-" + panelKey);
    // getting element top offset from the top of the viewport
    const elementPos = element.getBoundingClientRect().top;
    let distance: number;
    // calculating scroll position
    if (elementPos < 120) {
      // if the element is less than 120px from the top, this will trigger scroll in up direction
      // this means that we have to increase the spacing due to the component header moving
      distance = elementPos + window.scrollY - 190;
    } else {
      distance = elementPos + window.scrollY - 120;
    }
    // scrolling the window
    window.scrollTo({ top: distance, behavior: "smooth" });
    // if the element has input with type text, focus that input
    if (element.querySelector('input[type="text"]')) {
      // preventing scroll on focus is important, otherwise the browser will immediately scroll the element to the middle of the page
      // given that the element is outside of the viewport
      element.querySelector('input[type="text"]').focus({ preventScroll: true });
    } else if (element.querySelector('input[type="email"]')) {
      element.querySelector('input[type="email"]').focus({ preventScroll: true });
    } else if (element.querySelector("textarea")) {
      element.querySelector("textarea").focus({ preventScroll: true });
    }
  }

  openSubmitModal() {
    if (this.unsavedChangeDetected) {
      this.notificationDialogService
        .confirm('Autosave in progress', '', 'Please try again after autosave has finished', 'Ok', '600px')
        .then((confirmed) => {
          // if confirmed === true - user confirmed, if false - user clicked cancel button
          if (confirmed) {
            // 
          } else {
            // 
          }
        })
        .catch(() => {
          // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
        });
    } else {
      this.markQuestionsInvalid();
      if (this.shouldSubmit) {
        const capitalizedFormTitle = this.formDetails.name.charAt(0).toUpperCase() + this.formDetails.name.slice(1);
        this.checkboxDialogService
          .confirm(
            "Confirmation",
            `Ready to share your ${capitalizedFormTitle}? Confirm your submission.`,
            "Submit",
            "Cancel",
            "600px",
            this.commonService.isEmailNotificationsEnabled(),
            "Receive submission confirmation email",
            true
          )
          .then((confirmed) => {
            // confirmed can return true/false or an object { checkbox: true/false } for the checbox value in the modal
            if (confirmed) {
              this.makeInert = true;
              this.submitForm(confirmed.checkbox ? true : false);
            } else {
            }
          })
          .catch(() => {
            // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
          });
      } else {
        // show field errors
        this.formio.formio.checkValidity(null, true, null, false);
        // toggle flag to keep showing errors
        this.keepFieldErrors = true;
      };
    };
  }

  openEditModal() {
    this.confirmationDialogService
      .confirm(
        "Confirmation Needed",
        `Are you sure you want to edit your ${this.formDetails.name}? Once you are done editing, you will need to re-submit the form.`,
        "Edit",
        "Cancel",
        "600px"
      )
      .then((confirmed) => {
        // if confirmed === true - user confirmed, if false - user clicked cancel button
        if (confirmed) {
          this.unsubmitForm();
        } else {
        }
      })
      .catch(() => {
        // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
      });
  }

  openFormPreview(shouldPrint?: boolean) {
    const urlTree = `form/${this.formDetails.stageId}/display/${this.formDetails.userId}`;
    let formPreviewUrl: string;
    if (shouldPrint) {
      formPreviewUrl = this.router.serializeUrl(
        this.router.createUrlTree([urlTree], { queryParams: { print: true } })
      );
    } else {
      formPreviewUrl = this.router.serializeUrl(
        this.router.createUrlTree([urlTree])
      );
    };
    window.open(formPreviewUrl, '_blank');
  }

  markQuestionsValid(formSubmission: FormSubmissionPOC) {
    formSubmission.questionPanels.forEach(panel => {
      const questionElement = document.querySelector(`.formio-component-${panel.key}`);
      // check for valid questions and mark them as valid
      if (questionElement) {
        // valid panels where all questions within are optional
        if (panel.isValid && panel.allOptional) {
          // ^^ if the panel is valid and has only optional input inside
          // check if the panel has empty value inside
          const hasEmptyValue = this.hasEmptyValue(panel.key, formSubmission);
          if (hasEmptyValue && questionElement.classList.contains('question-marked-valid')) {
            // if the panel has empty value and has valid mark, remove it
            questionElement.classList.remove('question-marked-valid');
          } else if (hasEmptyValue && questionElement.classList.contains('question-marked-invalid')) {
            // if the panel has empty value and has invalid mark, remove it
            questionElement.classList.remove('question-marked-invalid');
          } else if (!hasEmptyValue && !questionElement.classList.contains('question-marked-valid')) {
            // if the panel does not have empty value and does not have valid mark, add it and remove invalid mark
            questionElement.classList.remove('question-marked-invalid')
            questionElement.classList.add('question-marked-valid');
          }
        } else if (panel.isValid) {
          // ^^ valid panels where not all question within are optional
          // add valid mark
          questionElement.classList.add('question-marked-valid');
          // remove invalid mark
          questionElement.classList.remove('question-marked-invalid')
        } else if (!panel.isValid) {
          // ^^ invalid panel
          // remove valid mark
          questionElement.classList.remove('question-marked-valid');
        }
      }
    });
  }

  hasEmptyValue(panelKey: string, formSubmission: FormSubmissionPOC): boolean {

    const panelComponents = this.formDefinition.components
      .find(x => x.type === 'panel' && x.key === panelKey).components
      .map(x => ({ key: x.key, type: x.type }));

    // this is populated with true/false booleans from the for cycle below
    // this is necessary to avoid breaking the for cycle by returning value directly
    const valueToReturn: [boolean?] = [];
    for (const panelComponent of panelComponents) {
      if (!formSubmission.data.hasOwnProperty(panelComponent.key)) {
        continue;
      }

      const valueAsObj = formSubmission.data[panelComponent.key];

      const hasEmptyValueEl = document.querySelector(`.formio-component-${panelComponent.key} .has-empty-value`);
      // this is a check for empty element inside a control (custom controls have this usually)
      // such an empty element is added when the selected value is cleared (but the element does not persist on refresh)
      // example - taxonomy, budget, location
      if (hasEmptyValueEl) {
        valueToReturn.push(true);
        // push true and skip to next iteration
        continue;
      }

      switch (panelComponent.type) {
        case 'selectboxes':
          // if all values in the select boxes are false, this means that no option was selected
          // thus returning true for empty value
          valueToReturn.push(Object.values(valueAsObj).every(x => x === false));
          // break the switch case to avoid evaluating selectboxes for the default conditions
          break;
        case 'taxonomy':
          if (valueAsObj) {
            // if value exists - this means the taxonomy has been tinkered with - selected/unselect
            // parse value and check inner value length - it's an array of selected options
            if (JSON.parse(valueAsObj).value.length > 0) {
              // if it's bigger than 0 - at least one option is selected - control is not empty
              valueToReturn.push(false);
            } else {
              // if it's not bigger than 0 - no options are selected, value is empty array - control is empty
              valueToReturn.push(true);
            }
          } else {
            // no value means, taxonomy in default state - empty
            valueToReturn.push(true);
          }
          break;
        default:
          if (Array.isArray(valueAsObj) && (valueAsObj.length === 0 || (valueAsObj.length === 1 && !valueAsObj[0]))) {
            // if the value is an array and if the length is 0 return true - empty value
            // if the value is an array and if the length is only one and the first value doesn't exist return true - empty value
            valueToReturn.push(true);
          } else if (!valueAsObj) {
            // if value doesn't exist return true - empty value
            valueToReturn.push(true);
          } else if (valueAsObj) {
            // if the value exists return false - not an empty value
            valueToReturn.push(false);
          }
          break;
      }
    }
    // check if all values in the array are true - all questions in the panel are empty - return true
    if (valueToReturn.every(x => x === true)) {
      return true;
    } else {
      // if not all values in the array are true - not all questions in the panel are empty - return false
      return false;
    }
  }

  async markQuestionsInvalid() {
    let invalidQuestions: any[];
    // TODO - update this to look inside formData.questionPanels instead of custom var
    // if formData.questionPanels does not exist, add all input panels to the list of invalid panels
    if (this.formData.questionPanels) {
      invalidQuestions = this.formData.questionPanels.filter(element => {
        if (!element.isValid) {
          return element;
        };
      });
    } else {
      // if we don't have formData.questionPanels, then we need to mark all panels as invalid
      invalidQuestions = this.scrollablePanels;
    }
    
    if (invalidQuestions.length > 0) {
      invalidQuestions.forEach(element => {
        const questionElement = document.querySelector(`.formio-component-${element.key}`);
        if (questionElement) {
          questionElement.classList.add('question-marked-invalid');
        }
        this.shouldSubmit = false;

        // getting element top offset from the top of the viewport
        const elementPos = document.querySelectorAll('.question-marked-invalid')[0].getBoundingClientRect().top;
        let distance;
        // calculating scroll position
        if (elementPos < 120) {
          // if the element is less than 120px from the top, this will trigger scroll in up direction
          // this means that we have to increase the spacing due to the component header moving
          distance = elementPos + window.scrollY - 190;
        } else {
          distance = elementPos + window.scrollY - 120;
        }
        // scrolling the window
        window.scrollTo({ top: distance, behavior: "smooth" });

      });
    } else {
      this.shouldSubmit = true;
    }
  }

  hasUnsavedData(): boolean {
    return this.unsavedChangeDetected;
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
}
