import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationDialogService } from '../services/notification-dialog.service';

export interface IUnsavedFormData {
    hasUnsavedData(): boolean
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedFormDataGuard  {

    constructor(private notificationDialogService: NotificationDialogService) {}

    canDeactivate(
        component: IUnsavedFormData,
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const hasUnsavedData = component.hasUnsavedData();
        return hasUnsavedData ? !this.notificationDialogService.confirm(
            'Slow down, Slow down',
            '',
            `Autosave is in progress.<br>Hold on. Stay on this page until autosave finishes.`,
            'Ok',
            '600px'
        ) : true
    }
  
}
