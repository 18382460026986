<div class="fullscreen">
  <div class="header">
    <div class="heading">
      <h2>Judge: {{ judgeName }}</h2>
    </div>
    <div class="actions">
      <button class="button button-primary button-w-icon--smaller" (click)="exit()">Exit</button>
    </div>
  </div>

  <div class="content">
    <div class="table-wrapper">
      <p-table
        #pTable
        [resizableColumns]="true"
        [autoLayout]="true"
        [lazy]="true"
        (onSort)="onSort($event)"
        [value]="data"
        [paginator]="true"
        [totalRecords]="data.length"
        [rows]="data.length"
        [columns]="columns"
        filterDelay="500"
        selectionMode="single"
      >
        <ng-template pTemplate="caption" class="row">
          <div class="combined-header" #componentHeader>
            <div class="first-row">
              <div class="caption-wrap">
                <button
                    (click)="refresh()"
                    class="button-restore-defaults refresh-wrap"
                    pTooltip="Refresh"
                    aria-label="Refresh table"
                  >
                    <i class="icon material-icons" aria-hidden="true">refresh</i>
                </button>
              </div>
              <div class="table-extras-wrap">
                <div [stageDeadline]="deadline" class="submission-deadline" pTooltip="Judging Deadline" tooltipPosition="bottom">
                  <span class="material-icons">schedule</span>
                  <span> {{ deadline | utcToCompTime: 'dateTransform' }} </span>
                  <span class="separator"></span>
                  <span> {{ deadline | utcToCompTime: 'timeTransform' }} </span>
                </div>
                <button
                  class="button button-w-icon button-w-icon--smaller"
                  (click)="clearFilters()"
                  [disabled]="!sortApplied"
                  aria-label="Clear filter and sorting"
                >
                  <span class="material-icons-outlined icon" aria-hidden="true">filter_alt_off</span>
                  Clear
                </button>
                <app-export-data (download)="onDownload($event)" pTooltip="Download"></app-export-data>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-row>
          <tr>
            <td class="action-header">Actions</td>
            <td *ngFor="let column of columns" pSortableColumn="{{ column.field }}">
              <span class="column-header" [innerHTML]="column.header"></span>
              <p-sortIcon field="{{ column.field }}"></p-sortIcon>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr [pSelectableRow]="row" [attr.aria-label]="'Position' + (rowIndex + 1)">
            <td class="action-column">
              <button (click)="menu.toggle($event)" class="menu-toggle">
                <span class="icon material-icons" aria-hidden="true">more_horiz</span>
              </button>
              <p-menu class="menu" #menu appendTo="body" [popup]="true" [model]="row.actions"></p-menu>
            </td>
            <td *ngFor="let col of columns" class="text-content-inside">
              <div
                *ngIf="col.field === 'submittedBy'"
                class="text-content"
                pTooltip="Click to copy"
                [tooltipDisabled]="row.submittedBy?.toString().length < 38"
                (click)="row.submittedBy?.toString().length > 37 && clickToCopy(row.submittedBy)"
              >
                {{ row.submittedBy }}
              </div>
              <div *ngIf="col.field === 'category'">
                {{ row.category }}
              </div>
              <div *ngIf="col.field === 'totalScore'">
                {{ row.totalScore }}
              </div>
              <div *ngIf="col.field === 'reviewComplete'">
                {{ row.reviewComplete ? 'Yes' : 'No' }}
              </div>
              <div *ngIf="col.field === 'traitsScored'">
                {{ row.traitsScored }}
              </div>
              <div *ngIf="col.field === 'traitsFeedback'">
                {{ row.traitsFeedback }}
              </div>
              <div *ngIf="col.field === 'overallImpression'">
                {{ row.overallImpression ? 'Yes' : 'No' }}
              </div>
              <div *ngIf="col.field === 'startDate'">
                {{ row.startDate | utcToCompTime: 'fullDateTime' }}
              </div>
              <div *ngIf="col.field === 'submittedDate'">
                {{ row.submittedDate | utcToCompTime: 'fullDateTime' }}
              </div>
              <div *ngIf="col.field === 'lastModified'">
                {{ row.lastModified | utcToCompTime: 'fullDateTime' }}
              </div>
              <div
                *ngIf="col.isUserFormData && row.userFormData"
                class="text-content"
                pTooltip="Click to copy"
                [tooltipDisabled]="row.userFormData[col.field]?.toString().length < 38"
                (click)="row.userFormData[col.field]?.toString().length > 37 && clickToCopy(row.userFormData[col.field])"
              >
                {{ row.userFormData[col.field] }}
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-cols>
          <tr>
            <td [attr.colspan]="cols.length">
              No records found
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorright" class="paginator-right">
          <span class="report-template">
            1 - {{ data.length }}
            <span>of</span>
            {{ data.length }}
          </span>
          <button
            class="button-icon icon-w-stroke icon-w-stroke--smaller ml-18"
            pTooltip="Configuration"
            *appHasRole="['Master Admin']"
            (click)="openConfigDialog()"
            aria-label="Open settings modal"
          >
            <span class="material-icons" aria-hidden="true">settings</span>
          </button>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>

<p-dialog
  styleClass="dialog"
  maskStyleClass="dialog-with-table"
  appendTo="body"
  [(visible)]="reassignDialog"
  modal="true"
  [closable]="false"
  [focusTrap]="dynamicFocusTrap"
>
  <div class="table-wrapper">
    <p-table
      #availableJudgesTable
      *ngIf="reassignDialog"
      [resizableColumns]="true"
      [autoLayout]="true"
      [lazy]="true"
      (onSort)="onSortAvailableJudges($event)"
      (onPage)="onPageAvailableJudges($event)"
      (onFilter)="onFilterAvailableJudges($event)"
      [value]="availableJudges"
      [(selection)]="selectedJudge"
      [columns]="availableJudgesColumns"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [totalRecords]="totalAvailableJudges"
      [rows]="availableJudgesRowsPerPage"
      selectionMode="single"
      paginatorDropdownAppendTo="body"
    >
      <ng-template pTemplate="caption" class="row">
        <div class="combined-header">
          <div class="first-row">
            <div class="caption-wrap">
              <h5>Reassign to Judge</h5>
              <button
                (click)="getAvailableJudges()"
                class="button-restore-defaults refresh-wrap"
                pTooltip="Refresh"
                aria-label="Refresh table"
                >
                <span class="icon material-icons">
                  refresh
                </span>
              </button>
            </div>
            <div class="table-extras-wrap">
              <button
                class="button button-w-icon button-w-icon--smaller"
                (click)="clearFiltersAvailableJudges()"
                [disabled]="!filterAppliedAvailableJudges && !sortAppliedAvailableJudges && !selectedJudge"
                aria-label="Clear filter and sorting"
              >
                <span class="icon material-icons-outlined" aria-hidden="true">filter_alt_off</span>
                Clear
              </button>
              <div class="search-wrapper">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input
                  #searchInputAvailableJudges
                  [readonly]="loading"
                  type="text"
                  (input)="availableJudgesTable.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-row>
        <tr>
          <td *ngFor="let column of availableJudgesColumns" pSortableColumn="{{ column.field }}">
            {{ column.header }}
            <p-sortIcon field="{{ column.field }}"></p-sortIcon>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
        <tr [pSelectableRow]="row" [attr.aria-label]="'Position' + (rowIndex + 1)">
          <td class="text-content-inside">
            <div
              class="text-content"
              pTooltip="Click to copy"
              [tooltipDisabled]="row.fullName?.toString().length < 38"
              (click)="row.fullName?.toString().length > 37 && clickToCopy(row.fullName)"
            >
              {{ row.fullName }}
            </div>
          </td>
          <td>{{ row.superJudgeStatus }}</td>
          <td>{{ row.assignedReviews }}</td>
          <td>{{ row.completedReviews }}</td>
          <td>{{ row.openReviews ? 'Yes' : 'No' }}</td>
          <td>{{ row.lastLogin | utcToCompTime: 'fullDateTime' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-cols>
        <tr>
          <td [attr.colspan]="cols.length">
            No records found
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft" class="paginator-left">
        <div>
          <p-paginator
            #availableJudgesPaginator
            (onPageChange)="paginateAvailableJudges($event)"
            [rows]="availableJudgesRowsPerPage"
            [totalRecords]="totalAvailableJudges"
            class="paginator"
          >
          </p-paginator>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorright" class="paginator-right">
        <span class="report-template">
          {{ availableJudgesFirst + 1 }} - {{ availableJudgesLast }}
          <span>of</span>
          {{ totalAvailableJudges }}
        </span>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button class="button button-secondary" (click)="reassignDialog = false">Cancel</button>
    <button class="button button-primary" (click)="reassignJudgment()" [disabled]="!selectedJudge">Reassign</button>
  </ng-template>
</p-dialog>
