import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ToastModule } from "primeng/toast";
import { NotificationToastService } from "../../services/notification-toast.service";

@Component({
  standalone: true,
  selector: "app-custom-toast",
  imports: [CommonModule, ToastModule],
  templateUrl: "./custom-toast.component.html",
  styleUrls: ["./custom-toast.component.scss"],
})
export class CustomToastComponent {
  @Input() key = NotificationToastService.DEFAULT_KEY;
  @Input() position = "bottom-right"; 

  async action($event: Event, callback: () => Promise<boolean>, closeFn: Function) {
    const success = await callback()
    if (success) {
      closeFn($event)
    }
  }
}
