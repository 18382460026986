<p-toast class="notification-toast" [position]="position" [key]="key" [preventOpenDuplicates]="true">
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <div class="custom-toast" [class]="message.data.type">
      <div class="custom-toast-content">
        <span class="material-icons custom-toast-icon" *ngIf="message.data.icon" aria-hidden="true">{{ message.data.icon }}</span>
        <p class="custom-toast-text" [innerHTML]="message.detail"></p>
      </div>
      <button class="custom-toast-action" (click)="action($event, message.data.action.onAction, closeFn)" [attr.aria-label]="message.data.action.text" *ngIf="message.data.action">
        {{ message.data.action.text }}
      </button>
      <button class="custom-toast-close" (click)="closeFn($event)" aria-label="Close notification" *ngIf="message.closable && !message.data.action">
        <span class="material-icons icon" aria-hidden="true">close</span>
      </button>
    </div>
  </ng-template>
</p-toast> 