<!-- logo part -->
<div class="logo-wrapper">
  <span class="loader loader-circle loader-logo" *ngIf="loaderLogo"></span>
  <a [routerLink]="['/']" class="logo-link" title="{{competitionName}}" *ngIf="!loaderLogo">
    <img class="logo-img" src="{{competitionLogo}}" alt="{{competitionLogoAltText}}">
    <h6 class="logo-text">{{competitionName}}</h6>
  </a>
</div>

<!-- navigation part -->
<div class="main-nav" *ngIf="(currentRpUser$ | async) as rpUser">
  <!-- left navigation -->
  <div class="nav-left" (clickOutside)="closeDropdownSubmission()">
    <span class="loader loader-line loader-nav-primary loader-double" *ngIf="loaderPrimaryNav"></span>
    <!-- wrapper for each navigation item -->
    <div class="btn-group navbarItem" *ngFor="let pList of PrimaryList; let i = index">
      <!-- primary navigation item if there are no children -->
      <div *ngIf="pList.children.length === 0">
        <!-- primary navigation item -->
        <a
          [routerLink]="[pList.navigationItemHref ? pList.navigationItemHref : router.url]"
          class="navbarItem"
        >
          {{pList.navigationItemName}}
        </a>
        <!-- end primary navigation item -->
      </div>
      <!-- end primary navigation item if there are no children -->
      <!-- primary navigation item if there are children! -->
      <div *ngIf="pList.children.length > 0">
        <!-- primary navigation item -->
        <button
          class="navbarItem subToggle"
          [ngClass]="{ open: submissionIndex === i, 'navbar-icon': pList.children.length }"
          (click)="toggleSubmission(i)"
          [attr.aria-label]="pList.navigationItemName + ' dropdown menu toggle'"
          aria-haspopup="true"
          [attr.aria-expanded]="submissionIndex === i ? 'true' : 'false'"
          [attr.aria-controls]="pList.navigationItemName + '-dropdown-menu'"
        >
          {{ pList.navigationItemName }}
        </button>
        <!-- end primary navigation item -->
        <!-- primary children items - if any -->
        <div
          class="dropdown-menu subDrop"
          *ngIf="pList.children.length"
          [ngClass]="{'show': submissionIndex === i}"
          [id]="pList.navigationItemName + '-dropdown-menu'"
          role="menu"
        >
          <!-- scroll wrapper -->
          <div class="scroll-wrapper" tabindex="-1" role="group">
            <div class="gradientscroll">
              <!-- inner wrapper for children items -->
              <div class="main">
                <!-- wrapper for each child -->
                <div class="main_item"
                  (click)="toggleSubSubmission(j)"
                  *ngFor="let pListChild of pList.children; let j = index"
                >
                  <!-- inner wrapper for each child -->
                  <div class="flexBlock">
                    <!-- actual link item -->
                    <a
                      [routerLink]="[pListChild.navigationItemHref]"
                      (click)="closeDropdownSubmission()"
                      routerLinkActive="is-active"
                      class="nav-link-nested"
                      [tabindex]="submissionIndex === i ? '' : '-1'"
                      role="menuitem"
                      [attr.aria-label]="pListChild.navigationItemName"
                    >
                      <div class="icon-wrapper" aria-hidden="true">
                        <mat-icon class="icon-left">
                          {{pListChild.navigationItemIcon ? pListChild.navigationItemIcon : 'description'}}
                        </mat-icon>
                      </div>
                      <div class="text-wrapper"><strong> {{pListChild.navigationItemName}}</strong></div>
                    </a>
                    <!-- end actual link item -->

                    <!-- child child items? -->
                    <div *ngIf="pListChild.children.length">
                      <span *ngIf="submissionSubIndex !== j" class="icon material-icons">
                        expand_more
                      </span>
                      <span *ngIf="submissionSubIndex === j" class="icon material-icons">
                        expand_less
                      </span>
                    </div>
                    <!-- end child child items? -->
                  </div>
                  <!-- end inner wrapper for each child -->

                  <!-- child child items? -->
                  <div class="block_Info" *ngIf="submissionSubIndex === j && pListChild.children.length">
                    <div class="item_group">
                      <div class="dropdown_item" [routerLink]="[pListSubChild.navigationItemHref]" *ngFor="let pListSubChild of pListChild.children; let k = index">
                        {{pListSubChild.navigationItemName}}
                      </div>
                    </div>
                  </div>
                  <!-- end child child items? -->
                </div>
                <!-- end wrapper for each child -->
              </div>
              <!-- end inner wrapper for children items -->
            </div>
          </div>
          <!-- end scroll wrapper -->
        </div>
        <!-- end primary children items - if any -->
      </div>
      <!-- end primary navigation item if there are children -->
    </div>
    <!-- end wrapper for each navigation item -->
  </div>
  <!-- end left navigation -->

  <!-- right navigation -->
  <div class="nav-right">
    <!-- globe menu -->
    <div class="btn-group btn-group-globe-menu">
      <span class="loader loader-circle loader-globe-menu" *ngIf="loaderGlobeMenu"></span>
      <div class="globe-menu"
        *ngIf="challengeSiteLinks.length"
        (clickOutside)="globeMenuSelected = false"
      >
        <!-- globe icon / dropdown trigger -->
        <button
          class="globe-menu-toggle"
          (click)="selectGlobeMenu()"
          aria-label="Challenge site dropdown menu toggle"
          aria-haspopup="true"
          [attr.aria-expanded]="globeMenuSelected === true ? 'true' : 'false'"
          aria-controls="globe-dropdown-menu"
        >
          <mat-icon aria-hidden="true" [ngStyle]="{'color': globeMenuSelected ? '#4E71FB' : '' }">language</mat-icon>
        </button>
        <!-- end globe icon / dropdown trigger -->
        <!-- dropdown menu -->
        <div class="dropdown-menu" [ngClass]="{'show': globeMenuSelected}" id="globe-dropdown-menu" role="menu">
          <!-- scroll wrapper -->
          <div class="scroll-wrapper" role="group">
            <div class="gradientscroll">
              <div class="scroll-inner">
                <!-- dropdown menu item -->
                <div class="dropdown-item-wrapper" *ngFor="let link of challengeSiteLinks">
                  <a
                    class="dropdown-item"
                    [href]="challengeLink(link.href)"
                    [tabindex]="globeMenuSelected ? '' : '-1'"
                    role="menuitem"
                    [attr.aria-label]="link.name"
                    target="_blank"
                  >
                    <mat-icon aria-hidden="true">language</mat-icon>
                    <span class="dropdown-text">{{link.name}}</span>
                  </a>
                </div>
                <!-- end dropdown menu item -->
              </div>
            </div>
          </div>
          <!-- end scroll wrapper -->
        </div>
        <!-- end dropdown menu -->
      </div>
    </div>
    <!-- end globe menu -->

    <!-- notifications -->
    <div class="nav-right-iconBlock btn-group" *ngIf="false">
      <span class="loader loader-circle" *ngIf="loaderNotifications"></span>
      <mat-icon aria-hidden="true" *ngIf="!loaderNotifications">notifications</mat-icon>
    </div>
    <!-- end notifications -->

    <!-- profile menu -->
    <div
      class="btn-group nav-right-iconBlock userInfoMenu"
      (clickOutside)="closeProfileMenu()"
    >
      <!-- profile menu icon / dropdown trigger -->
      <button
        (click)="toggleProfileMenu()"
        class="profile-button-toggle"
        aria-label="Profile menu toggle"
        aria-haspopup="true"
        [attr.aria-expanded]="showProfileMenu === true ? 'true' : 'false'"
        aria-controls="profile-menu"
      >
        <mat-icon class="profile-icon" [ngClass]="{'userInfoMenuSelected': showProfileMenu}" aria-hidden="true">account_circle</mat-icon>
      </button>
      <!-- end profile menu icon / dropdown trigger -->
      <!-- dropdown menu wrapper -->
      <div class="dropdown-menu avatar_dropdown" [ngClass]="{'showProfile': showProfileMenu}" id="profile-menu" role="menu">
        <!-- avatar wrapper -->
        <div class="dropdown-avatar">
          <!-- avatar icon -->
          <div class="user-icon__block">
            <mat-icon aria-hidden="true">account_circle</mat-icon>
          </div>
          <!-- end avatar icon -->
          <!-- user info -->
          <div *ngIf="rpUser" class="text_block">
            <span class="dropdown-avatar_text">{{rpUser.firstName}} {{rpUser.lastName}}</span>
            <p class="dropdown-avatar_text">{{rpUser.currentRole.name}}</p>
          </div>
          <!-- end user info -->
        </div>
        <!-- end avatar wrapper -->

        <!-- change role dropdown -->
        <div class="change_role" [ngClass]="{'change_role_Selected': changeRoleSelected}" *ngIf="rpUser.roles.length > 1">
          <!-- change role text / icon -->
          <button
            class="change_role__text"
            (click)="changeRoleSelected = !changeRoleSelected"
            aria-label="Change role options toggle"
            aria-haspopup="true"
            [attr.aria-expanded]="changeRoleSelected === true ? 'true' : 'false'"
            aria-controls="change-role-menu"
            role="menuitem"
          >
            <div class="icon-wrap" aria-hidden="true">
              <mat-icon>account_circle</mat-icon>
            </div>
            <strong class="item-text">Change Role</strong>
            <span *ngIf="!changeRoleSelected" class="icon material-icons" aria-hidden="true">
              expand_more
            </span>
            <span *ngIf="changeRoleSelected" class="icon material-icons" aria-hidden="true">
              expand_less
            </span>
          </button>
          <!-- end change role text / icon -->
          <!-- list of roles dropdown -->
          <div class="block_Info" *ngIf="changeRoleSelected" id="change-role-menu" role="menu">
            <div class="dropdown_item" *ngFor="let role of rpUser.roles; let i = index">
              <button
                class="dropdown_item--button"
                [ngClass]="{'selected': role.id == rpUser.currentRole.id}"
                [disabled]="role.id == rpUser.currentRole.id"
                (click)="setRole(role.id)"
                [attr.aria-label]="'Change your role to ' + role.name"
                [attr.aria-disabled]="role.id == rpUser.currentRole.id ? 'true' : 'false'"
                role="menuitem"
              >
                {{role.name}}
                <span class="checkmark"></span>
              </button>
              <!-- <label class="dropdown_item--label">
                <input
                  type="radio"
                  [checked]="role.id == rpUser.currentRole.id"
                  (click)="setRole(role.id)"
                  [disabled]="role.id == rpUser.currentRole.id"
                >
                {{role.name}}
                <span class="checkmark" ></span>
              </label> -->
            </div>
          </div>
          <!-- end list of roles dropdown -->
        </div>
        <!-- end change role dropdown -->

        <!-- account settings link -->
        <div class="main_item">
          <a
            href="{{accountSettingsURL}}"
            target="_blank"
            class="dropdown-option"
            role="menuitem"
            aria-label="Account settings"
          >
            <mat-icon aria-hidden="true" class="icon">settings</mat-icon>
            <strong>Account Settings</strong>
          </a>
        </div>
        <!-- end account settings link -->
        <!-- logout button -->
        <div class="main_item">
          <button
            (click)="logout()"
            class="dropdown-option logout"
            role="menuitem"
            aria-label="Logout"
          >
            <mat-icon aria-hidden="true" class="icon">logout</mat-icon>
            <strong>Logout</strong>
          </button>
        </div>
        <!-- end logout button -->
      </div>
      <!-- end dropdown menu wrapper -->
    </div>
    <!-- end profile menu -->

    <!-- help link -->
    <div class="nav-right-iconBlock btn-group" *ngIf="false">
      <span class="loader loader-circle" *ngIf="loaderHelp"></span>
      <mat-icon aria-hidden="true" *ngIf="!loaderHelp">help</mat-icon>
    </div>
    <!-- end help link -->

    <!-- secondary navigation -->
    <div
      class="btn-group nav-right-iconBlock"
      (clickOutside)="closeDropdownSidebar()"
    >
      <span class="loader loader-circle loader-sidebar" *ngIf="loaderSidebar"></span>
      <!-- secondary navigation icon / dropdown trigger -->
      <button
        class="navbarItem sidebar-menu-wrap"
        [ngStyle]="{'color': showSidebar ? '#5A7BFE' : null}"
        (click)="toggleSidebar()"
        *ngIf="SecondaryList?.length"
        aria-label="Navigation menu toggle"
        aria-haspopup="true"
        [attr.aria-expanded]="showSidebar === true ? 'true' : 'false'"
        aria-controls="navigation-menu"
      >
        <div class="sidebar-menu" [ngClass]="{'active': showSidebar}">
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-top"></div>
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-middle"></div>
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-bottom"></div>
        </div>
      </button>
      <!-- end secondary navigation icon / dropdown trigger -->
      <!-- secondary navigation dropdown -->
      <div class="dropdown-menu sidebar-dropdown" [class.show]="showSidebar" id="navigation-menu" role="menu">
        <!-- inner wrapper -->
        <div class="main" role="group">
          <!-- wrapper for each child item -->
          <div class="wrapper" *ngFor="let secList of SecondaryList; let i = index" [ngClass]="{'divider-wrap': secList.navigationItemName === 'Forums' || secList.navigationItemName === 'Dashboard'}">
            <div class="divider" *ngIf="secList.navigationItemName === 'Forums' || secList.navigationItemName === 'Dashboard'"></div>

            <!-- if there ARE NO children, this will be rendered -->
            <a
              class="main_item"
              *ngIf="secList.children.length === 0"
              [routerLink]="[secList.navigationItemHref ? secList.navigationItemHref : null]"
              [ngClass]="{'main_itemSelected': chooseUrl(secList.navigationItemName) && secondaryNavIndex !== i, 'expanded': secondaryNavIndex === i}"
              role="menuitem"
              [attr.aria-label]="secList.navigationItemName"
            >
              <div class="flexBlock">
                <div>
                  <!-- icon wrapper -->
                  <span class="icon-wrap" aria-hidden="true">
                    <mat-icon fontSet="material-icons-round" [ngStyle]="{'color': chooseUrl(secList.navigationItemName) ? '#5A7BFE' : null}">
                      {{secList.navigationItemIcon ? secList.navigationItemIcon : 'description'}}
                    </mat-icon>
                  </span>
                  <!-- end icon wrapper -->
                  <!-- text wrapper -->
                  <strong class="item-text">{{secList.navigationItemName}}</strong>
                  <!-- end text wrapper -->
                </div>
              </div>
            </a>

            <!-- if there ARE children, this will be rendered -->
            <div
              class="main_item" 
              *ngIf="secList.children.length > 0"
              [ngClass]="{'main_itemSelected': chooseUrl(secList.navigationItemName) && secondaryNavIndex !== i, 'expanded': secondaryNavIndex === i}"
            >
              <!-- parent element -->
              <button
                class="flexBlock main_item--button"
                (click)="toggleSecondary(i)"
                [attr.aria-label]="secList.navigationItemName + ' submenu toggle'"
                aria-haspopup="true"
                [attr.aria-expanded]="secondaryNavIndex === i ? 'true' : 'false'"
                [attr.aria-controls]="'navigation-sub-menu-' + i"
                role="menuitem"
              >
                <div>
                  <span class="icon-wrap" >
                    <mat-icon fontSet="material-icons-round" aria-hidden="true" [ngStyle]="{'color': chooseUrl(secList.navigationItemName) ? '#5A7BFE' : null}">
                      {{secList.navigationItemIcon ? secList.navigationItemIcon : 'description'}}
                    </mat-icon>
                  </span>
                  <strong class="item-text">{{secList.navigationItemName}}</strong>
                </div>
                <div class="icon-wrap-arrow" aria-hidden="true">
                  <span *ngIf="secondaryNavIndex !== i" class="icon material-icons">
                    expand_more
                  </span>
                  <span *ngIf="secondaryNavIndex === i" class="icon material-icons">
                    expand_less
                  </span>
                </div>
              </button>
              <!-- end parent element -->
              <!-- child wrapper -->
              <div class="block_Info" *ngIf="secondaryNavIndex === i && secList.children.length" [id]="'navigation-sub-menu-' + i" role="menu">
                <!-- child link wrapper / active link -->
                <div
                  class="dropdown_item dropdown_item--secondary-nav"
                  [ngClass]="{'dropdown_itemSelected': secSubList.navigationItemHref === compiledURL}"
                  *ngFor="let secSubList of secList.children; let j = index" (click)="toggleSubSecondary(j)"
                >
                  <!-- actual link -->
                  <a
                    class="dropdown_item--link"
                    [routerLink]="[secSubList.navigationItemHref]"
                    [ngStyle]="{'color': secSubList.navigationItemHref === compiledURL ? '#5A7BFE' : null}"
                    role="menuitem"
                    [attr.aria-label]="secSubList.navigationItemName"
                  >
                    {{secSubList.navigationItemName}}
                  </a>
                  <!-- end actual link -->
                </div>
                <!-- end child link wrapper / active link -->
              </div>
              <!-- end child wrapper -->
            </div>
          </div>
          <!-- end wrapper for each child item -->
        </div>
        <!-- end inner wrapper -->
      </div>
      <!-- end secondary navigation dropdown -->
    </div>
    <!-- end secondary navigation -->
  </div>
  <!-- end right navigation -->
</div>
<!-- end navigation part -->