import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";
import { ConfirmationDialogService } from "src/app/shared/services/confirmation-dialog.service";
import { EvaluationJudgingService } from "src/app/shared/services/evaluation-judging.service";
import { TabTitleService } from "src/app/shared/services/tab-title.service";

@Component({
  selector: "app-evaluation-judging-review-view",
  templateUrl: "./evaluation-judging-review-view.component.html",
  styleUrls: ["./evaluation-judging-review-view.component.scss"],
})
export class EvaluationJudgingReviewViewComponent implements OnInit {
  summary = []
  description = ""

  stageId: string = '';
  reviewId: string = '';

  stageName = 'Evaluation'
  participantName = '';

  isDeadlinePassed = false
  isSoftDeadlineEnabled = false;

  formsArray = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private evaluationJudgingService: EvaluationJudgingService,
    private tabTitleService: TabTitleService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.stageId = params.id;
      this.reviewId = params.reviewId;
      const res: any = await this.evaluationJudgingService.getJudgementSteps(this.reviewId).toPromise();
      this.participantName = res.participantName;
      this.isDeadlinePassed = res.stageEndDate < DateTime.fromJSDate(new Date()).setZone('UTC').toISO();
      this.isSoftDeadlineEnabled = res.isSoftDeadlineEnabled;
      this.formsArray = res.data.find(step => step.type === "formsreview")?.forms?.map((form: any) => {
        return {
          label: form.title,
          url: `form/${form.formStageId}/display/${res.participantUserId}`,
          target: '_blank'
        }
      });
      res.data
      .filter((step: any) => step.type === 'trait' || step.type === 'overallimpression' || step.type === 'submit')
      .forEach((step: any) => {
        let sum = {
          score: step.type === 'submit' ? step.totalScore : step.score,
          criteria: step.type === 'submit' ? 'Total score' : step.title,
          step: step.stepCount, 
          expanded: false, 
          showFeedbackOverlay: step.comment && step.comment.length > 80 ? true : false,
          feedback: step.comment
        };
        if (step.type === 'submit') {
          this.description = step.summaryDescriptionReadOnly;
        }
        this.summary.push(sum);
      });
    })
    this.tabTitleService.setTabTitle(`${this.stageName}`);
  }

  exit() {
    this.router.navigate([`evaluation/${this.stageId}`]);
  }

  async edit() {
    this.confirmationDialogService
    .confirm('Edit Review', `Editing a completed review will reopen it and the scores and comments you have provided will be available for correction.<br/>Note that you have to complete the reopened review before the judging deadline.`, 'Continue', 'Cancel', '600px').then(async res => {
      if (res) {
        await this.evaluationJudgingService.editReview(this.reviewId).toPromise();
        this.router.navigate([`evaluation/${this.stageId}/review/${this.reviewId}`]);
      }
    })
  }

  expandFeedback(sum: any, index: number) {
    sum.expanded = !sum.expanded;
    const clickedTrait = document.querySelectorAll('.summary-table tr')[index + 1];
    const descHeight = clickedTrait.querySelector('.summary-feedback').clientHeight;
    const sumFeedbackWrap = clickedTrait.querySelector('.summary-feedback-wrap');

    let finalHeight: number;

    if (descHeight > 28) {
      finalHeight = descHeight + 5;
      if (sum.expanded === true) {
        sumFeedbackWrap.setAttribute('style', `height: ${finalHeight}px;`);
      } else {
        sumFeedbackWrap.setAttribute('style', '');
      }
    }
  }

}
