import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivationStart,
  NavigationEnd,
  Router,
} from "@angular/router";
import { CommonService } from "./shared/services/common.service";
import { LoaderService } from "./shared/services/loader.service";
import { StorageService } from "./shared/services/storage.service";
import { Location } from "@angular/common";
import { GradientConfig } from "./app-config";
import { filter } from "rxjs/operators";
import { CompetitionInformation } from "./shared/model/common.model";
import { PublicConfigService } from "./shared/services/public.config.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as CookieConsent from "vanilla-cookieconsent";
import { CookieConsentConfigService } from "./shared/services/cookie-consent-config.service";
import { SsoAuthServiceNew } from "./shared/services/sso-auth-new.service";
import { NotificationToastService } from "./shared/services/notification-toast.service";
import { StickyHeaderService } from "./shared/services/sticky-header.service";

interface componentHeaderRef {
  available?: boolean;
  elementRef?: ElementRef;
}

// we need this for GTM
declare function gtag(...args: any): void;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public loader = false;
  windowScrolled: boolean = false;
  public hideNav = true;
  public hideFooter = false;
  public loginPageVisible = false;
  public pageWrapperStopLoader = false;
  public fullwidth = false;

  @ViewChild("customNavbarOuterWrapper") customNavbarOuterWrapper: ElementRef;
  public gradientConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  public isDashboardActive: boolean = false;

  public loginPageBackgroundImage: string = '';
  public competitionLogo: string = '';
  public competitionLogoAltText: string = '';
  public competitionName: string = '';
  public currentYear: number = new Date().getFullYear();
  public compiledHeaderTitle: string = '';

  public supportEmailAddress: string = '';

  private componentHeaderRef: componentHeaderRef = {
    available: false,
    elementRef: undefined,
  };

  public cookiesEnabled: boolean = false;

  public overlayLogo: string = '';
  // mobile check on initial load, we also check if browser is not headless since this will break pdf export
  public isMobile = window.innerWidth <= 992 && !/HeadlessChrome/.test(window.navigator.userAgent);

  constructor(
    private router: Router,
    public loaderService: LoaderService,
    private commonService: CommonService,
    private storage: StorageService,
    private location: Location,
    public ssoAuthService: SsoAuthServiceNew,
    public publicConfig: PublicConfigService,
    public googleTagManagerService: GoogleTagManagerService,
    private cookieConsentConfigService: CookieConsentConfigService,
    private notificationToastService: NotificationToastService,
    private stickyHeaderService: StickyHeaderService
  ) {
    this.gradientConfig = GradientConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location["_baseHref"];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (
      currentURL === baseHerf + "/layout/collapse-menu" ||
      currentURL === baseHerf + "/layout/box" ||
      (this.windowWidth >= 992 && this.windowWidth <= 1024)
    ) {
      this.gradientConfig.collapseMenu = true;
    }

    this.navCollapsed = this.windowWidth >= 992 ? this.gradientConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  async ngOnInit() {
    // js check if cookies are enabled
    this.cookiesEnabled = navigator.cookieEnabled;

    const competitionLogo = this.storage.retrieve(CompetitionInformation.brandingsLogo);
    if (competitionLogo !== null) {
      this.competitionLogo = `${competitionLogo}`;
    };
    const competitionName = this.storage.retrieve(CompetitionInformation.competitionName);
    if (competitionName !== null) {
      this.competitionName = `${competitionName}`;
    };
    const competitioFavicon = this.storage.retrieve(CompetitionInformation.brandingsFavicon);
    if (competitioFavicon) {
      document.querySelector('link[rel="icon"]').setAttribute("href", competitioFavicon);
    };
    const logoAltTextFromStorage = this.storage.retrieve('competitionLogoAltText');
    if ((logoAltTextFromStorage !== '') && (logoAltTextFromStorage !== null)) {
      this.competitionLogoAltText = logoAltTextFromStorage;
    };

    this.commonService.competitionInfo
    .pipe(filter((v) => v))
    .subscribe((value) => {
      // fetching branding logo and support email address, to avoid getting them from localStorage
      // in this way they can be shown correctly even when cookies and localStorage are disabled
      this.overlayLogo = value.data?.brandingsLogo;
      this.supportEmailAddress = value.data?.supportEmailAddress;
    });

    this.router.events.subscribe((evt) => {
      if (evt instanceof ActivationStart) {
        this.hideNav = evt.snapshot.data.hideNav === undefined ? false : true;
        this.hideFooter = evt.snapshot.data.hideFooter === undefined ? false : true;
        this.pageWrapperStopLoader = evt.snapshot.data.pageWrapperStopLoader === undefined ? false : true;
        this.loginPageVisible = evt.snapshot.data.loginPageVisible === undefined ? false : true;
        this.fullwidth = evt.snapshot.data.fullwidth === undefined ? false : true;

        if (this.loginPageVisible) {
          this.commonService.competitionInfo
            .pipe(filter((res) => !!res))
            .subscribe(() => {
              const competitionLoginPageFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLoginPageBackgroundImage);
              if (competitionLoginPageFromStorage !== null) {
                this.loginPageBackgroundImage = `url("${competitionLoginPageFromStorage}")`;
              };
            });
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // used for hiding the background shape on the dashboard screen
      if (evt instanceof NavigationEnd) {
        // removing the previous component header ref only on navigation end;
        this.stickyHeaderService.removeComponentHeaderRef();
        if (
          evt.urlAfterRedirects === "/dashboard" ||
          evt.urlAfterRedirects === "/dashboard-edit"
        ) {
          this.isDashboardActive = true;
        } else {
          this.isDashboardActive = false;
        }
      }

      if (!this.loginPageVisible) {
        // scroll to top when navigating to a new page
        // on all pages without the login pages
        window.scrollTo(0, 0);
      };
    });

    this.stickyHeaderService.getComponentHeaderAsObs().subscribe(componentHeaderRef => {
      this.componentHeaderRef = componentHeaderRef;
    });

     // offline and online listeners to disable/enable app
     window.addEventListener('offline', this.offlineListener);
     window.addEventListener('online', this.onlineListener);
  }

  ngAfterViewInit(): void {
    // check if cookies are enabled and run cookie consent only if they are enabled
    if (this.cookiesEnabled) {
      const cookieConsentConfig = this.cookieConsentConfigService.getConfig();
      CookieConsent.run({
        ...cookieConsentConfig,
        onChange: async ({ cookie, changedCategories, changedServices }) => {
          gtag("consent", "update", {
            analytics_storage: cookie.categories.includes("analytics")
              ? "granted"
              : "denied",
            ad_storage: cookie.categories.includes("marketing")
              ? "granted"
              : "denied",
          });
          await this.cookieConsentConfigService.saveConsent(cookie.categories, 'change', this.publicConfig.getCookieInfoStorageUrl());
        },
        onFirstConsent: async ({ cookie }) => {
          await this.cookieConsentConfigService.saveConsent(cookie.categories, 'accept', this.publicConfig.getCookieInfoStorageUrl());
        },
        onConsent: async ({ cookie }) => {
          gtag("consent", "update", {
            analytics_storage: cookie.categories.includes("analytics")
              ? "granted"
              : "denied",
            ad_storage: cookie.categories.includes("marketing")
              ? "granted"
              : "denied",
          });
          await this.googleTagManagerService.addGtmToDom();
        },
        onModalShow: ({ modalName }) => {
          if (modalName === "preferencesModal") {
            document.querySelector("html").classList.add("modal-opened");
          }
        },
        onModalHide: ({ modalName }) => {
          if (modalName === "preferencesModal") {
            document.querySelector("html").classList.remove("modal-opened");
          }
        },
      });
    }

    // check impersonation info
    this.ssoAuthService.impersonationInfo$.subscribe(impInfo => {
      if (impInfo) {
        this.notificationToastService.notify(
          { 
            key: 'impersonation',
            type: 'info', 
            message: `You are now impersonating <b>${impInfo.impersonatedFullName}</b>`, 
            sticky: true,
            action: {
              text: 'End Impersonation',
              onAction: async () => {
                await this.ssoAuthService.stopImpersonate();
                return true
              }
            }
          }
        );
      }
    });
  }

  offlineListener = () => {
    // disable page clicks
    document.querySelector('body').setAttribute('inert', 'true');
    this.notificationToastService.notify(
      { 
        type: 'warning', 
        message: `We've lost our connection to you, so we've disabled editing to prevent any issues. Trying to reconnect...`, 
        sticky: true,
        icon: 'wifi_off',
        closable: false
      }
    );
  }

  onlineListener = () => {
    // enable page clicks
    document.querySelector('body').removeAttribute('inert');
    this.notificationToastService.clear()
    this.notificationToastService.notify(
      { 
        type: 'success', 
        message: `Connection restored`, 
        timeout: 5000
      }
    );
  }

  onScrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll($event: Event) {
    this.scroll($event);
  }

  // mobile check on resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // we also check if browser is not headless since this will break pdf export
    this.isMobile = window.innerWidth <= 992 && !/HeadlessChrome/.test(window.navigator.userAgent);
  }

  // TODO - this is not needed anymore?
  async getCompetitionInformation() {
    const favicon = this.storage.retrieve("competitionFavicon");
    if (favicon) {
      document.querySelector('link[rel="icon"]').setAttribute("href", favicon);
    };
    const response: any = await this.commonService.GetCompetitionInformation().toPromise();
    if (response.data.brandingsFavicon && !favicon) {
      document.querySelector('link[rel="icon"]').setAttribute("href", response.data.brandingsFavicon);
    }
  }

  scroll = (event): void => {
    this.windowScrolled = window.pageYOffset >= 2000;
    let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // TODO redesign - remove this, because we don't have sticky platform header
    // if the page is scrolled and component header is not available, add solo style class to header
    if (currentScrollPosition > 0 && !this.componentHeaderRef.available) {
      this.customNavbarOuterWrapper?.nativeElement?.classList.add('scrolled-solo');
    } else {
      this.customNavbarOuterWrapper?.nativeElement?.classList.remove('scrolled-solo');
    }

    // if component header is available
    if (this.componentHeaderRef.available) {
      if (currentScrollPosition > 80) {
        // sticky component header
        if (!this.componentHeaderRef.elementRef.nativeElement.classList.contains("on-top")) {
          this.componentHeaderRef.elementRef.nativeElement.classList.add("on-top");
          this.componentHeaderRef.elementRef.nativeElement.classList.add('on-top-with-header');
        }
      } else {
        // non sticky component header
        this.componentHeaderRef.elementRef.nativeElement.classList.remove("on-top");
        this.componentHeaderRef.elementRef.nativeElement.classList.remove("on-top-with-header");
      }
    }
  };

  customNavbarOuterWrapperOnTop() {
    if (!this.customNavbarOuterWrapper.nativeElement.classList.contains("on-top")) {
      this.customNavbarOuterWrapper.nativeElement.classList.add("on-top");
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.onlineListener);
    window.removeEventListener('offline', this.offlineListener);
  }
}
