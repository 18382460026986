import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class NotificationToastService {

  static DEFAULT_KEY = "custom-toast";

  constructor(private messageService: MessageService) {}

  notify(options: {
    key?: string;
    timeout?: number;
    sticky?: boolean;
    message: string;
    type?: "info" | "warning" | "success" | "error";
    closable?: boolean;
    icon?: string;
    action?: { text: string; onAction: () => Promise<boolean> };
  }) {
    if (!options.icon && options.type) {
      switch (options.type) {
        case "info":
          options.icon = "info";
          break;
        case "success":
          options.icon = "check_circle";
          break;
        case "error":
          options.icon = "error";
          break;
        case "warning":
          options.icon = "warning";
          break;
      }
    }

    this.messageService.add({
      severity: "custom",
      life: options.timeout || 5000,
      key: options.key || NotificationToastService.DEFAULT_KEY,
      detail: options.message,
      sticky: options.sticky ?? false,
      closable: options.closable ?? true,
      data: {
        type: options.type || "info",
        icon: options.icon || "info",
        action: options.action,
      },
    });
  }

  clear(key = NotificationToastService.DEFAULT_KEY) {
    this.messageService.clear(key);
  }
}
