import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorComponent } from "./error/error.component";
import { MasterGuard } from "./shared/guards/master.guard";
import { EvaluationOverviewDetailsComponent } from "./stages/evaluation/overview-details/evaluation-overview-details.component";
import { PeerOverviewDetailsComponent } from "./stages/peer-review/overview-details/peer-overview-details.component";
import { AdminReviewViewReviewComponent } from "./stages/admin-review/review/view/admin-review-view-review.component";
import { AdminReviewEditReviewComponent } from "./stages/admin-review/review/edit/admin-review-edit-review.component";
import { EvaluationJudgingReviewComponent } from "./stages/evaluation/judging-review/evaluation-judging-review.component";
import { FormSubmissionResolverService } from "./forms/services/form-submission-resolver.service";
import { FormStageTypes } from "./forms/model/form-details";
import { DefaultFormsSubmissionResolverService } from "./forms/services/default-forms-submission-resolver.service";
import { EvaluationJudgingReviewViewComponent } from "./stages/evaluation/judging-review-view/evaluation-judging-review-view.component";
import { FormDisplayResolverService } from "./forms/services/form-display-resolver.service";
import { PeerJudgingReviewViewComponent } from "./stages/peer-review/judging-review-view/peer-judging-review-view.component";
import { PeerJudgingReviewComponent } from "./stages/peer-review/judging-review/peer-judging-review.component";
import { LoginComponent } from "./auth/login/login.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { RegisterComponent } from "./auth/register/register.component";
import { VerifyEmailComponent } from "./auth/verify-email/verify-email.component";
import { RedirectIfLoggedInGuard } from "./shared/guards/redirectIfLoggedIn.guard";
import { GuestComponent } from "./guest/guest.component";
import { RegistrationClosedComponent } from "./registration-closed/registration-closed.component";
import { EmptyRedirectComponent } from "./empty-redirect-component/empty-redirect.component";
import { FormRendererComponent } from "./forms/form-renderer/form-renderer.component";
import { UnsavedFormDataGuard } from "./shared/guards/unsaved-form-data.guard";
import { FormDisplayRendererComponent } from "./forms/form-display-renderer/form-display-renderer.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import("./dashboard/dashboard.module").then(
        (module) => module.DashboardModule
      ),
    data: {
      only: [
        "Master Admin",
        "Competition Admin",
        "Client",
        "Admin Reviewer",
        "Judge",
        "Participant",
      ],
    },
  },
  {
    path: "dashboard-edit",
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import("./dashboard-edit/dashboard-edit.module").then(
        (module) => module.DashboardEditModule
      ),
    data: {
      only: [
        "Master Admin",
      ],
    },
  },
  // {
  //   path: "judging",
  //   canActivate: [MasterGuard],
  //   loadChildren: () =>
  //     import("./judging/judging.module").then((module) => module.JudgingModule),
  // },
  // forms
  {
    path: "consent",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./consent/consent.module").then((module) => module.ConsentModule),
    data: {
      only: [
        "Master Admin",
        "Competition Admin",
        "Client",
        "Admin Reviewer",
        "Judge",
        "Participant",
      ],
    },
  },
  {
    path: "forms",
    redirectTo: "/form-manager",
  },
  {
    path: "form-manager",
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin"],
    },
    loadChildren: () =>
      import("./forms/form-manager/form-manager.module").then((module) => module.FormManagerModule),
  },
  {
    path: "charts",
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin", "Competition Admin", "Client"],
    },
    loadChildren: () =>
      import("./charts/charts.module").then((module) => module.ChartsModule),
  },
  {
    path: "form-data",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./forms-data/forms-data.module").then((module) => module.FormsDataModule),
      data: {
        only: ["Master Admin", "Competition Admin"],
      }, 
  },
  {
    path: "competition-test",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./competition-test/competition-test.module").then((module) => module.CompetitionTestModule),
      data: {
        only: ["Master Admin"],
      }, 
  },
  {
    path: "forums",
    canActivate: [MasterGuard],
    loadChildren: () =>
    import("./forums/forums.module").then((module) => module.ForumsModule),
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Participant"],
    }, 
  },
  {
    path: "registration",
    component: FormRendererComponent,
    canActivate: [MasterGuard],
    canDeactivate: [UnsavedFormDataGuard],
    resolve: { formDetails: DefaultFormsSubmissionResolverService },
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Participant"],
      stageType: FormStageTypes.RegistrationForm,
      fullwidth: true,
    },
  },
  {
    path: "application",
    canActivate: [MasterGuard],
    canDeactivate: [UnsavedFormDataGuard],
    component: FormRendererComponent,
    resolve: { formDetails: DefaultFormsSubmissionResolverService },
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Participant"],
      stageType: FormStageTypes.ApplicationForm,
      fullwidth: true,
    },
  },
  {
    path: "form/:stageId/display/:userId",
    canActivate: [MasterGuard],
    component: FormDisplayRendererComponent,
    resolve: { formDetails: FormDisplayResolverService }
  },
  {
    path: "form/:stageId",
    canActivate: [MasterGuard],
    canDeactivate: [UnsavedFormDataGuard],
    component: FormRendererComponent,
    resolve: { formDetails: FormSubmissionResolverService },
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Participant"],
      fullwidth: true,
    },
  },
  {
    path: "form/:stageId/:userId",
    canActivate: [MasterGuard],
    component: FormRendererComponent,
    resolve: { formDetails: FormSubmissionResolverService },
    data: {
      only: ["Master Admin"],
      fullwidth: true,
    },
  },
  {
    path: "form-renderer",
    canActivate: [MasterGuard],
    component: FormRendererComponent,
  },
  // stages
  {
    path: "admin-review",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./stages/admin-review/admin-review.module").then(
        (module) => module.AdminReviewModule
      ),
  },
  {
    path: "bulk-review",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./stages/bulk-review/bulk-review.module").then(
        (module) => module.BulkReviewModule
      ),
  },
  {
    path: "evaluation",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./stages/evaluation/evaluation.module").then(
        (module) => module.EvaluationModule
      ),
  },
  {
    path: "peer-review",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./stages/peer-review/peer-review.module").then(
        (module) => module.PeerReviewModule
      ),
  },
  {
    path: "scoring-rubric",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./scoring/scoring.module").then(
        (module) => module.ScoringModule
      ),
    data: { only: ["Master Admin"] },
  },
  {
    path: "registered-confirmed",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./registered-and-confirmed/registered-and-confirmed.module").then(
        (module) => module.RegisteredAndConfirmedModule
      ),
    data: {
      only: ["Master Admin", "Competition Admin", "Client"],
      fullwidth: true,
    },
  },
  {
    path: "registered-confirmed/:stageId",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./registered-and-confirmed/registered-and-confirmed.module").then(
        (module) => module.RegisteredAndConfirmedModule
      ),
    data: {
      only: ["Master Admin", "Competition Admin", "Client"],
      fullwidth: true,
    },
  },
  {
    path: "user-list",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./user-list/user-list.module").then(
        (module) => module.UserListModule
      ),
    data: {
      only: ["Master Admin"],
      fullwidth: true,
    },
  },
  {
    path: "general-setup",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./general-setup/general-setup.module").then(
        (module) => module.GeneralSetupModule
      ),
    data: { only: ["Master Admin"] },
  },
  {
    path: "judge-list",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import("./judge-management/judge-management.module").then(
        (module) => module.JudgeManagementModule
      ),
    data: {
      only: ["Master Admin", "Competition Admin"],
      fullwidth: true,
    },
  },
  {
    path: "ar-list",
    canActivate: [MasterGuard],
    loadChildren: () =>
      import(
        "./admin-reviewer-management/admin-reviewer-management.module"
      ).then((module) => module.AdminReviewerManagementModule),
    data: {
      only: ["Master Admin", "Competition Admin"],
      fullwidth: true,
    },
  },
  {
    path: "kitchen-sink",
    loadChildren: () =>
      import("./kitchen-sinks/kitchen-sinks.module").then(
        (module) => module.KitchenSinksModule
      ),
  },
  {
    path: "login",
    canActivate: [RedirectIfLoggedInGuard],
    component: LoginComponent,
    data: {
      hideNav: true,
      loginPageVisible: true,
      pageWrapperStopLoader: true,
    },
  },
  {
    path: "register",
    canActivate: [RedirectIfLoggedInGuard],
    component: RegisterComponent,
    data: {
      hideNav: true,
      loginPageVisible: true,
      pageWrapperStopLoader: true,
    },
  },
  {
    path: "forgot-password",
    canActivate: [RedirectIfLoggedInGuard],
    component: ForgotPasswordComponent,
    data: {
      hideNav: true,
      loginPageVisible: true,
      pageWrapperStopLoader: true,
    },
  },
  {
    path: "reset-password/:token",
    canActivate: [RedirectIfLoggedInGuard],
    component: ChangePasswordComponent,
    data: {
      hideNav: true,
      loginPageVisible: true,
      pageWrapperStopLoader: true,
    },
  },
  {
    path: "verify-email/:token",
    canActivate: [RedirectIfLoggedInGuard],
    component: VerifyEmailComponent,
    data: {
      hideNav: true,
      loginPageVisible: true,
      pageWrapperStopLoader: true,
    },
  },
  {
    path: "admin-review/:id/review/:reviewId/overview",
    component: AdminReviewViewReviewComponent,
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Admin Reviewer"],
      hideNav: true,
      hideFooter: true,
    },
  },
  {
    path: "admin-review/:id/review/:reviewId/edit",
    component: AdminReviewEditReviewComponent,
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin", "Competition Admin", "Client", "Admin Reviewer"],
      hideNav: true,
      hideFooter: true,
    },
  },
  {
    path: "evaluation/:id/overview/:judge/details",
    component: EvaluationOverviewDetailsComponent,
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin", "Competition Admin", "Client"],
      hideNav: true,
      pageWrapperStopLoader: true,
      hideFooter: true,
      fullwidth: true,
    },
  },
  {
    path: "evaluation/:id/review/:reviewId",
    component: EvaluationJudgingReviewComponent,
    canActivate: [MasterGuard],
    data: {
      hideNav: true,
      pageWrapperStopLoader: true,
      only: ["Master Admin", "Judge"],
      hideFooter: true,
    },
  },
  {
    path: "evaluation/:id/review/:reviewId/view",
    component: EvaluationJudgingReviewViewComponent,
    canActivate: [MasterGuard],
    data: {
      hideNav: true,
      pageWrapperStopLoader: true,
      only: ["Master Admin", "Competition Admin", "Judge"],
      hideFooter: true,
    },
  },
  {
    path: "peer-review/:id/overview/:reviewer/details",
    component: PeerOverviewDetailsComponent,
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin", "Competition Admin", "Client"],
      hideNav: true,
      pageWrapperStopLoader: true,
      hideFooter: true,
      fullwidth: true,
    },
  },
  {
    path: "peer-review/:id/review/:reviewId",
    component: PeerJudgingReviewComponent,
    canActivate: [MasterGuard],
    data: {
      hideNav: true,
      pageWrapperStopLoader: true,
      only: ["Master Admin", "Participant"],
      hideFooter: true,
    },
  },
  {
    path: "peer-review/:id/review/:reviewId/view",
    component: PeerJudgingReviewViewComponent,
    canActivate: [MasterGuard],
    data: {
      hideNav: true,
      pageWrapperStopLoader: true,
      only: ["Master Admin", "Competition Admin", "Participant"],
      hideFooter: true,
    },
  },
  {
    path: "guest",
    component: GuestComponent,
    canActivate: [MasterGuard],
    data: {
      only: ["Master Admin","Guest"],
    }
  },
  {
    path: "registration-closed",
    component: RegistrationClosedComponent,
    canActivate: [MasterGuard],
  },
  {
    path: 'empty-redirect',
    component: EmptyRedirectComponent,
  },
  {
    path: "**",
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
