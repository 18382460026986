import {
    Directive,
    Input,
    OnInit,
    ElementRef,
    OnChanges,
    SimpleChanges,
  } from '@angular/core';
import { DateTime, Interval } from 'luxon';
  
  @Directive({
    selector: '[stageDeadline]',
  })
  export class StageDeadlineDirective implements OnInit, OnChanges  {
    constructor(
      private el: ElementRef,
    ) {}
    
    @Input() stageDeadline = null

    currentDate = DateTime.fromJSDate(new Date()).setZone('UTC').toISO();
  
    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
      if (this.stageDeadline) {
        this.changeColor()
      }
    }

    changeColor() {
      const element: HTMLElement = this.el.nativeElement;

      const icon: HTMLElement = element.querySelector('.material-icons')
      const separator: HTMLElement = element.querySelector('.separator')

      const deadline = DateTime.fromISO(this.stageDeadline);
      const current =  DateTime.fromISO(this.currentDate);

      const diff = Interval.fromDateTimes(current, deadline);
      const diffDays = diff.length('days');

      let background = '#D1EFD8';
      let color = '#19AE3A';

      if (diffDays < 5 && diffDays > 1) {
        background = '#FDF1D2';
        color = '#F6B81C';
      } else if (diffDays < 1) {
        background = '#F9D6D1';
        color = '#E03019';
      } else if (isNaN(diffDays)) {
        background = '#F9F9FA';
        color = '#5E616D';
      }

      if (icon) {
        icon.style.color = color;
      }

      if (separator) {
        separator.style.backgroundColor = color;
      }

      element.style.backgroundColor = background;
    }
  }
  