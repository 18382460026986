import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAdminBulkReviewOperations, IEvaluationConfiguration, IEvaluationOperations, IFormReviewStages, IPeerConfiguration, IPeerOperations, IUpdateAdBkConfiguration, IUpdateStageQuestionsRequest } from "../model/IStages.model";
import { PublicConfigService } from "./public.config.service";

@Injectable(
  { providedIn: 'root' }
)

export class StagesService {

  public publicConfig: any;

  constructor(private httpClient: HttpClient, publicConfigService: PublicConfigService) {
    this.publicConfig = publicConfigService;
  }

  GetStageDetails(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}`, {

    });
  }

  GetStagePermissionsList() {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/permissions`, {

    });
  }

  GetStagePermissionsPerPermission(stageId: string, permissionId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/${stageId}/stagePermissions/${permissionId}`, {

    });
  }

  PutStagePermissionsPerPermission(data) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/stages/stagePermissions`, data, {

    });
  }

  GetAdBkConfiguration(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/reviewSetup`, {

    });
  }

  PutAdBkConfiguration(data: IUpdateAdBkConfiguration) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/reviewConfiguration`, data, {

    });
  }

  GetAdBkOperations(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/reviewOperations`, {

    });
  }

  PutAdBkOperations(data: IAdminBulkReviewOperations) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/reviewOperations`, data, {

    });
  }

  GetStageQuestionTypes() {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/stageQuestionTypes`, {

    });
  }

  GetStageQuestion(stageId: string, questionTypeId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/${stageId}/stageQuestions/${questionTypeId}`, {

    });
  }

  GetStageQuestionsFeedback(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/${stageId}/stageQuestionsFeedback`, {

    })
  }

  UpdateListStageQuestions(data: IUpdateStageQuestionsRequest) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/stages/stageQuestions`, data, {

    });
  }

  GetPeerConfiguration(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/peerReviewSetup`, {

    });
  }

  PutPeerConfiguration(data: IPeerConfiguration) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/peerReviewConfiguration`, data, {

    });
  }

  GetPeerOperations(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/peerReviewOperations`, {

    });
  }

  PutPeerOperations(data: IPeerOperations) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/peerReviewOperations`, data, {

    });
  }

  GetformReviewStages(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stages/${stageId}/formReviewStages`, {

    });
  }

  PutformReviewStages(data: IFormReviewStages) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/stages/formReviewStages`, data, {

    });
  }

  GetEvaluationConfiguration(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/evaluationSetup`, {

    });
  }

  PutEvaluationConfiguration(data: IEvaluationConfiguration) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/evaluationConfiguration`, data, {

    });
  }

  GetEvaluationOperations(stageId: string) {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/evaluationOperations`, {

    });
  }

  PutEvaluationOperations(data: IEvaluationOperations) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/evaluationOperations`, data, {

    });
  }

  GetParticipantsEligibleToAdvance(stageId: string, stageDistributionType?: string) {
    let params = new HttpParams();
    if (stageDistributionType) {
      params = params.append('stageDistributionType', stageDistributionType);
    }
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stage/${stageId}/get-eligible-to-advance`, { params });
  }

  AdvanceParticipants(stageId: string, distributionFile?: File, stageDistributionType?: string) {
    const formData = new FormData()
    formData.append('StageId', stageId)
    if (distributionFile) {
      formData.append('DistributionFile', distributionFile)
    }
    if (stageDistributionType) {
      formData.append('stageDistributionType', stageDistributionType)
    }
    return this.httpClient.post(`${this.publicConfig.rpBaseUrl}/api/stage/advance-participants`, formData);
  }

  getDistributionFileTemplate() {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stage/distribution-template-file`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  ClearJudgingRecords(stageId: string) {
    return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/judging/${stageId}/reset`, {});
  }
}
